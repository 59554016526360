<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <div class="card">
        <div class="card-body">
          <table class="table datatable" ref="datatable">
            <thead>
              <tr>
                <th>{{ $t("FORM.ID") }}</th>
                <th>{{ $t("FORM.FULL_NAME") }}</th>
                <th>{{ $t("FORM.EMAIL") }}</th>
                <th>{{ $t("FORM.PHONE") }}</th>
                <th>{{ $t("LIST.CREATED_AT") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.id }}</td>
                <td>{{ user.firstName }} {{ user.lastName }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.phone }}</td>
                <td>
                  {{ user.createdAt | filterDateTime }}
                </td>
                <td class="qr px-0 text-center">
                  <button
                    v-if="showButtons"
                    class="edit-button"
                    @click.stop.prevent="prepareLink(user)"
                  >
                    <b-icon-pencil variant="primary"></b-icon-pencil>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";

export default {
  components: {},
  props: ["companyId"],
  data() {
    return {
      users: {},
      hasDeletedUser: 0,
      showButtons: undefined,
      isLoading: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SETTINGS") },
      { title: this.$t("ROUTES.USERS") }
    ]);
  },
  watch: {
    companyId: function() {
      this.fetchUsers();
    }
  },
  methods: {
    ...mapActions({
      getUsers: "company/GET_USERS",
      deleteSelectedUser: "user/DELETE_USER",
      setSelectedUser: "user/SET_SELECTED_USER",
      clearSelectedUser: "user/CLEAR_SELECTED_USER"
    }),
    async fetchUsers() {
      this.isLoading = true;
      this.showButtons = !this.companyId;
      const users = await this.getUsers(this.companyId);
      this.users = await users.users;
      this.hasDeletedUser = users.deletedUsersCount;
      this.isLoading = false;
    },
    prepareLink(user) {
      const action = user ? this.setSelectedUser : this.clearSelectedUser;

      action(user).then(() => {
        this.$router.push({
          name: "settings-users-user",
          params: { activeUser: true, deleteable: this.users.length > 1 }
        });
      });
    },
    prepareDeletedLink() {
      this.$router.push({ name: "settings-users-deleted" });
    }
  },
  async created() {
    this.fetchUsers();
  }
};
</script>

<style lang="scss" scoped>
.edit-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
</style>
